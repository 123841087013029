// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-contentful-book-link-js": () => import("./../../../src/pages/books/{contentfulBook.link}.js" /* webpackChunkName: "component---src-pages-books-contentful-book-link-js" */),
  "component---src-pages-contentful-page-link-js": () => import("./../../../src/pages/{contentfulPage.link}.js" /* webpackChunkName: "component---src-pages-contentful-page-link-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-contentful-blog-post-id-js": () => import("./../../../src/pages/posts/{contentfulBlogPost.id}.js" /* webpackChunkName: "component---src-pages-posts-contentful-blog-post-id-js" */)
}

